import { useEffect, useState } from 'react'

export default function useLocalStorage<T>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] {
  const [value, setValue] = useState<T>(defaultValue)

  useEffect(() => {
    try {
      const storedValue = localStorage.getItem(key)
      if (storedValue) {
        setValue(JSON.parse(storedValue))
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    try {
      setTimeout(() => {
        localStorage.setItem(key, JSON.stringify(value))
      }, 50)
    } catch (error) {
      console.error(error)
    }
  }, [key, value])

  return [
    value,
    setValue,
    () => {
      setValue(defaultValue)
    },
  ]
}
